<template>
  <div id="login" class="py-3">
      <b-overlay :show="isLoading" rounded="sm">
        <h2 class="mb-3">Login To My Account</h2>
          <b-alert variant="danger" show v-if="errorMessage">{{errorMessage}}</b-alert>
          <form id="login-form">
              <b-form-group
                      id="username-input-group"
                      label="Username"
                      label-for="username"
                      description=""
              >
                  <b-form-input
                          :class="{
                              'is-invalid': $v.loginCred.username.$dirty && $v.loginCred.username.$error,
                              'is-valid': $v.loginCred.username.$dirty && !$v.loginCred.username.$error
                          }"
                          id="username"
                          v-model.trim="$v.loginCred.username.$model"
                          type="text"
                          placeholder=""
                          required
                  ></b-form-input>
              </b-form-group>

              <b-form-group
                      id="password-input-group"
                      label="Password"
                      label-for="password"
                      description=""
              >
                  <b-form-input
                          :class="{
                              'is-invalid': $v.loginCred.password.$dirty && $v.loginCred.password.$error,
                              'is-valid': $v.loginCred.password.$dirty && !$v.loginCred.password.$error
                          }"
                          id="password"
                          v-model.trim="$v.loginCred.password.$model"
                          type="password"
                          placeholder=""
                          required
                  ></b-form-input>
              </b-form-group>

              <b-alert show>
                  By logging in you accept our latest <a href="/terms-and-conditions">Terms and Conditions</a>
              </b-alert>

              <b-row class="mb-3">
                <b-col lg="6" class="pb-2">
                  <b-button class="btn-block" variant="success" :disabled="$v.loginCred.$invalid" @click="loginToMyAccount">
                    Login
                  </b-button>
                </b-col>
                <b-col lg="6" class="pb-2">
                <b-button href="" class="btn-block" variant="light" @click.prevent="$store.state.signup.type='new_user'">
                  Register
                </b-button>
                </b-col>
              </b-row>
              <div>
                <router-link :to="{name: 'ForgotPasswordPage'}"  @click.prevent="$store.state.signup.type='new_user'">I Forgot My Password</router-link>
              </div>
<!--              <b-button variant="link" href="#"-->
<!--                        @click.prevent="$store.state.signup.type='forgot_password'"-->
<!--              >Forgot Password</b-button>-->
          </form>
      </b-overlay>
  </div>
</template>

<script>
import {required, email, minLength} from "vuelidate/lib/validators";
import buyissolutionsApiService from "@/services/buyissolutions.api.service";
import authTokenHelper from "@/helpers/authToken.helper";

export default {
    name: "LoginForm",
    components: {},
    props: ['loginDetails'],
    computed: {
    },
    data() {
        return {
            isLoading: false,
            errorMessage: null,
            loginCred: {
                username: null,
                password: null
            }
        }
    },
    methods: {
      async setHeartBeat() {
        return setInterval(() => {}, 10000);
      },
      loginToMyAccount() {
          this.$mixpanel.track('Login', {
            'username': this.loginCred.username
          });

            this.isLoading = true;
            buyissolutionsApiService.loginToAccount(this.loginCred.username, this.loginCred.password).then(response => {
                if (response.status === 200) {
                    authTokenHelper.setToken(response.data.token).then(() => {
                        buyissolutionsApiService.getLoggedInUser().then(async response => {
                          if (response.status === 200) {
                            this.$store.state.loggedUser = response.data.user

                            console.log('set interval has been set');

                            this.$mixpanel.identify(response.data.user.id);
                            this.$mixpanel.people.set(
                                {
                                  "user_id": response.data.user.id,
                                  "$name": response.data.user.first_name,
                                  "$email": response.data.user.email
                                }
                            );

                            this.$store.state.intervalID = await this.setHeartBeat();

                            console.log(this.$store.state.intervalID)
                          }
                          this.$bvModal.hide('register-login-forms');
                          if (this.$route.name !== 'ShoppingCart') {
                            // location.href = '/dashboard';
                            await this.$router.push({name: 'HomePage'});
                            return;
                          }

                        }).finally(() => {
                            this.isLoading = false;
                        });
                    });
                }  else {
                    alert('something terribly wrong has happened here')
                }
                this.isLoading = false;
            }).catch(error => {
                this.errorMessage = error.response.data.message;
            }).finally(() => {
                this.isLoading = false;
            });
        }
    },
    // watch: {
    //     username(newUsername, oldUsername) {
    //         if (newUsername !== oldUsername) {
    //             this.$store.state.loginCred.username = newUsername
    //         }
    //     },
    //     password(newPassword, oldPassword) {
    //         if (newPassword !== oldPassword) {
    //             this.$store.state.loginCred.password = newPassword
    //         }
    //     },
    //     // deep: true
    // },
    validations: {
        loginCred: {
            username: {
                required,
                email
            },
            password: {
                required,
                minLength: minLength(6)
            }
        }
    }
}
</script>

<style scoped>

</style>