<template>
<div id="single-product-page" class="page">
  <b-overlay :show="isLoading" rounded="sm" class="content">
    <div class="sub-page-header" style="
              position: sticky;
              top: 99px;
              background: #6c757e;
              z-index: 99999999;
              padding-top: 15px;
              padding-bottom: 16px;
          ">
      <div class="container">
        <div class="row">
          <div class="col-md-12">
            <div class="top-header">
              <h4 class="text-white" v-if="selectedProduct">{{ selectedProduct.description }}</h4>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="page-content">
      <div class="container">
        <div class="row">
          <div class="col-sm-12">
            <b-alert v-if="error_message" show variant="danger">
              {{error_message}}
            </b-alert>
            <b-form>
              <b-form-group
                  id="input-group-1"
                  label="MTN Mobile Number"
                  label-for="input-1"
                  description=""
              >
                <b-form-input
                    :class="{
                  'is-invalid': $v.productSignUp.msisdn.$dirty && $v.productSignUp.msisdn.$error,
                  'is-valid': $v.productSignUp.msisdn.$dirty && !$v.productSignUp.msisdn.$error
                }"
                    id="input-1"
                    v-model.trim="$v.productSignUp.msisdn.$model"
                    type="text"
                    placeholder="0731234567"
                    required
                ></b-form-input>

              </b-form-group>
              <b-button :disabled="$v.productSignUp.$invalid" @click="buyNow">Buy Now</b-button>
            </b-form>
          </div>
        </div>
      </div>
    </div>

  </b-overlay>
</div>
</template>

<script>
import buyissolutionsApiService from "@/services/buyissolutions.api.service";
import cartHelper from "@/helpers/cart.helper";
import {minLength, numeric, required} from "vuelidate/lib/validators";

export default {
    name: "SingleProduct",
    components: {},
    props: [],
    computed: {
    },
    created () {
      this.$mixpanel.track_pageview({"page": "Single Product Page"});
    },
    mounted() {
      this.isLoading = true;
        if (this.$route.params.product_id) {
            buyissolutionsApiService.getProductById(this.$route.params.product_id).then(response => {
                this.selectedProduct = response.data.product;
                this.isLoading = false
            })
        } else {
          alert('Something went wrong, please go back and select a product.');
        }
    },
    data() {
        return {
            isLoading: false,
            selectedProduct: null,
            error_message: null,
            productSignUp: {
              msisdn: null
            }
        }
    },
    methods: {
      isMsisdnAvailable(msisdn) {
        this.error_message = null;
        this.isLoading = true;
        buyissolutionsApiService.checkMsisdnAvailability(msisdn).then((response) => {
          console.log(response.data.code);
          if (response.data.code === 200) {
            return true;
          }
        }).catch(error => {
          if (error.response.data.code === 409) {
            this.error_message = error.response.data.message;
          } else {
            this.error_message = 'Opps, something went wrong.';
          }
          this.isLoading = false;
          return false;
        })
        .finally(() => {
          this.isLoading = false;
        });

        return false;
      },
      addToCart(cartItemRequirements = []) {

        this.$mixpanel.track('Add To Cart', {
          'selected_product': this.selectedProduct,
          'cart_item_requirements': cartItemRequirements
        });

        cartHelper.addProductToCart(this.selectedProduct, cartItemRequirements).then(() => {
          buyissolutionsApiService.getLoggedInUser().then(response => {
            if (response.status === 200) {
              this.$router.push({'name': 'ShoppingCart'});
              return
            }
          }).catch(error => {
            if (error.response.status === 401) {
              this.$router.push({'name': 'ShoppingCart'});
              this.$store.state.signup.type = 'new_user';
              this.$bvModal.show('register-login-forms');
            }
          });
        });
      },

      async buyNow() {
        this.$mixpanel.track('Check MSISDN Availability', {
          'msisdn': this.productSignUp.msisdn
        });

        this.error_message = null;
        this.isLoading = true;
        buyissolutionsApiService.checkMsisdnAvailability(this.productSignUp.msisdn).then((response) => {
          if (response.data.code === 200) {
            let cartItems = [
              {'key': 'msisdn', 'value': this.productSignUp.msisdn},
              {'key': 'sim', 'value': 'none'}
            ];


            this.$mixpanel.track('Check MSISDN Available', {
              'msisdn': this.productSignUp.msisdn
            });
            this.addToCart(cartItems);
            return true;
          }
        }).catch(error => {
          if (error.response.data.code === 409) {
            this.error_message = error.response.data.message;

            this.$mixpanel.track('Check MSISDN Not Available', {
              'msisdn': this.productSignUp.msisdn,
              'error': error.response.data.message
            });
          } else {
            this.error_message = 'Opps, something went wrong.';
          }
          this.isLoading = false;
          return false;
        })
        .finally(() => {
          this.isLoading = false;
        });
      }
    },
  validations: {
    productSignUp: {
      msisdn: {
        required,
        minLength: minLength(10),
        numeric
      },
    }
  }
}
</script>

<style scoped>
</style>