<template>
  <div id="client-product-top-up-page" class="page">

    <b-overlay :show="isLoading" rounded="sm" class="content">
      <div class="sub-page-header" style="
              position: sticky;
              top: 99px;
              background: #6c757e;
              z-index: 99999999;
              padding-top: 15px;
              padding-bottom: 16px;
          ">

        <div class="container">
          <div class="row">
            <div class="col-md-12">
              <div class="top-header">
                <b-button pill :to="{'name': 'SingleClientProductPage', params: {'client_product_id': client_product.id}}" variant="link">
                  <b-icon icon="arrow-left-circle" class="text-white" scale="2"></b-icon>
                </b-button>

                <h4 class="text-white">Topup My Account</h4>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="page-content">
        <div class="container">

          <div class="row">
            <div class="col-md-12">
              <h5>{{ client_product.friendly_name }}</h5>
              <data-usage-information-card class="mb-5"
                   v-if="data_usage_information"
                   :allocated="data_usage_information.allocated"
                   :used="data_usage_information.used"
                   :used_percentage="data_usage_information.used_percentage"
              />
            </div>
          </div>

          <div class="row">
            <div class="col-md-12">

              <b-form-group id="input-group-3" label="Top Up Options:" style="margin-top: 20px" label-for="input-3">
                <b-form-select
                    id="input-3"
                    :class="{
                                              'is-invalid': $v.selectedTopUpProductId.$dirty && $v.selectedTopUpProductId.$error,
                                              'is-valid': $v.selectedTopUpProductId.$dirty && !$v.selectedTopUpProductId.$error
                                        }"
                    v-model.trim="$v.selectedTopUpProductId.$model"
                    :options="topUpOptions"
                    required
                ></b-form-select>
              </b-form-group>

              <form name="paynowForm" id="paynowForm" method="POST" action="https://paynow.netcash.co.za/site/paynow.aspx" class="d-none" target="_top"> <!---Form POST **--->
                <input type="text" name="M1" value="8e3da9e9-2e92-44d7-8ba9-4c1dd33c5fd9"> <!--- YOUR PAY NOW SERVICE KEY GOES IN HERE --->
                <input type="text" name="M2" value="c9b2dd2d-b0ed-40be-a92d-e4e60783a397"> <!--- SOFTWARE VENDOR KEY GOES IN HERE --->
                <input type="text" name="p2" v-model="paymentInformation.reference"> <!---Unique ID for this / each transaction--->
                <input type="text" name="p3" v-model="paymentInformation.description"> <!---Description of goods being purchased--->
                <input type="text" name="p4" v-model="paymentInformation.sell_price"> <!---Amount to be settled / paid--->
                <input type="text" name="m9" v-model="paymentInformation.client_email"> <!---Card holders email address--->
                <input type="text" name="m11" value=""> <!---Card holders mobile number--->
                <input type="text" name="Budget" value="N">
                <input type="text" name="m11" value=""><!---Card holders mobile number--->
                <input type="text" name="m4" v-model="paymentInformation.transaction_id"> <!---This is an extra field ***--->
                <input type="text" name="m5" value=""> <!---This is an extra field ***--->
                <input type="text" name="m6" v-model="paymentInformation.slug"> <!---This is an extra field ***--->
                <input type="text" value="PAY">
              </form>

              <div class="d-flex gap-1">
                <!--            <b-button variant="secondary" @click="$router.go(-1)">Back</b-button>-->
                <b-button variant="success" block :disabled="$v.selectedTopUpProductId.$invalid" @click="payNow(clientProduct)">
                  Pay Now
                </b-button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </b-overlay>
  </div>
</template>
<script>

import buyissolutionsApiService from "@/services/buyissolutions.api.service";
import currencyHelper from "@/helpers/currencyHelper";
import {required} from "vuelidate/lib/validators";
import DataUsageInformationCard from "@/v4/components/DataUsageInformationCard.vue";

export default {
  name: "ClientProductTopUpPage",
  components: {DataUsageInformationCard},
  created () {
    this.$mixpanel.track_pageview({"page": "Client Product Top Up Page"});
  },
  computed: {
    getClientProductId() {
      return this.$route.params.client_product_id;
    }
  },
  mounted() {
    console.log('clientProductId', this.getClientProductId)
    this.getClientProduct();

    this.isLoading = true;
    buyissolutionsApiService.getClientProduct(this.$route.params.client_product_id).then(response => {
      if (response.status === 200) {
        this.clientProduct = response.data.client_product;

        buyissolutionsApiService.getClientProductTopUpOptions(this.$route.params.client_product_id).then(response => {
          if (response.status === 200) {
            const topUpOptions = response.data.top_up_options;
            this.topUpOptions.push({value: null, text: 'Select to up product'})
            topUpOptions.forEach((val) => {
              this.topUpOptions.push({value: val.id, text: val.description + ' - R' + val.sell_price })
            });
          }
        });
      }
    }).finally(() => {
      this.isLoading = false;
    });
  },
  data() {
    return {
      isLoading: true,
      client_product: [],
      clientProduct: null,
      paymentInformation: {
        reference: null,
        description: null,
        sell_price: null,
        client_email: null,
        slug: null,
        transaction_id: null,
      },
      topUpOptions: [],
      selectedTopUpProductId: null,
    }
  },
  methods: {
    getClientProduct() {
      this.isLoading = true;
      buyissolutionsApiService.getClientProduct(this.getClientProductId).then(result => {
        this.client_product = result.data.client_product
        this.data_usage_information = result.data.data_usage_information
      }).finally(() => {
        this.isLoading = false
      })
    },
    payNow(clientProduct) {
      this.$mixpanel.track('Top Up Payment Attempted', {
        'selected_product_id': clientProduct.id,
        'selected_top_up_product_id': this.selectedTopUpProductId
      });

      this.isLoading = true;

      buyissolutionsApiService.initialTopUp(clientProduct.id, this.selectedTopUpProductId).then(response => {
        if (response.status === 200) {
          this.paymentInformation.reference = response.data.reference;
          this.paymentInformation.description = response.data.client_product.product.description;
          this.paymentInformation.sell_price = currencyHelper.formatPrice(response.data.transaction.amount);
          this.paymentInformation.client_email = response.data.client_product.client.email;
          this.paymentInformation.transaction_id = response.data.transaction.id;
          this.paymentInformation.slug = response.data.slug;

          this.$mixpanel.track('submit_paynow_form_top_up', this.paymentInformation);

          setTimeout(function() {
            this.isLoading = true;
            document.getElementById("paynowForm").submit();
          }, 10);
        }
      }).finally(() => {
        this.isLoading = false;
      })
    }
  },
  validations: {
    selectedTopUpProductId: {
      required
    }
  }
}
</script>

<style scoped>

</style>